
					$oemKey: naxeon;
					$oem-brand-color: #494949;
					$oem-brand-color-light: #EDEDED;
				
						$oem-font-family-stack: #{'Reddit Sans', sans-serif};
					
						$oem-font-family-url: "https://fonts.googleapis.com/css2?family=Reddit+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
					
/**
 *	Styles
 */

// ekho dealer component library import
@import 'node_modules/@ekhodealer/ekho-common/dist/scss/styles';

// @import 'settings/index';
// @import 'tools/index';
// @import 'generic/index';
// @import 'elements/index';
// @import 'objects/index';
@import 'components/index';
// @import 'vendors/index';
// @import 'utilities/index';
// @import 'node_modules/bootstrap/scss/maps';
// @import 'node_modules/bootstrap/scss/utilities/api';
// @import 'custom/index';

#militaryCheckbox {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.pac-container {
	z-index: 1056;
}
